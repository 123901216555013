<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="销售政策编码">
            <el-input v-model="search.code" clearable placeholder="请输入销售政策编码" />
          </el-form-item>
          <el-form-item label="销售政策名称">
            <el-input v-model="search.search" clearable placeholder="请输入销售政策名称" />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <div></div>
        <el-button type="primary" round class="mb10" size="mini" @click="tapBtn('添加','')">添加销售政策</el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="编号" prop="id" width="80" />
            <ElTableColumn label="销售政策编码" prop="sales_policy" />
            <ElTableColumn label="销售政策名称" prop="sales_policy_name" />
            <ElTableColumn label="描述" prop="sales_policy_desc" />

            <ElTableColumn label="操作" fixed="right" width="150">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('编辑',row)">编 辑</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
    <!-- 回访添加 -->
    <el-dialog
      :title="`${titText}销售政策`"
      :visible.sync="visitClient"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-form
        ref="detail"
        label-position="right"
        label-width="120px"
        :rules="rules"
        :model="detail"
        class="visit"
      >
        <el-form-item label="销售政策编码" prop="sales_policy">
          <el-input v-model="detail.sales_policy" class="w300" placeholder="请填写销售政策编码" />
        </el-form-item>
        <el-form-item label="销售政策名称" prop="sales_policy_name">
          <el-input v-model="detail.sales_policy_name" class="w300" placeholder="请填写销售政策名称" />
        </el-form-item>
        <el-form-item label="销售政策描述">
          <el-input
            v-model="detail.sales_policy_desc"
            class="w300"
            placeholder="请填写销售政策描述"
            type="textarea"
          />
        </el-form-item>

        <div class="footerBtn">
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('detail')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import { clientList, getCustomerShopList, ssettingList } from "@/api/client";
import { readAdminMarketList } from "@/api/shop";
import { staffList } from "@/api/staff";
import { policyList, createPolicy, updatePolicy } from "@/api/order";
import { returnReturnVistT, addReturnVistT } from "@/api/againVisit";

class Search {
  code = "";
  search = "";
}
class Options {
  shop_id = [];
  customer_id = [];
  market_id = [];
  setting_id = [];
  nurse_id = [];
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Detail {
  sales_policy = "";
  sales_policy_name = "";
  sales_policy_desc = "";
}
class Rules {
  sales_policy = [
    { required: true, message: "请输入销售政策编码", trigger: "blur" }
  ];
  sales_policy_name = [
    { required: true, message: "请输入销售政策名称", trigger: "blur" }
  ];
}
export default {
  name: "ClientList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(),
      options: new Options(),
      page: new Page(),
      detail: new Detail(),
      rules: new Rules(),
      list: [],
      visitClient: false,
      updateId: "",
      titText: "添加"
    };
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  mounted() {
    this.getOptions();
    this.getList();
  },
  methods: {
    // 获取选项
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 9) {
            that.options.setting_id.push(item);
          }
        });
      });
      readAdminMarketList().then(res => {
        this.options.market_id = res.list;
      });
      clientList({ page: 1, limit: 1000000 }).then(res => {
        this.options.customer_id = res.list;
      });
      getCustomerShopList({ page: 1, limit: 1000000, type: 0 }).then(res => {
        this.options.shop_id = res.data;
      });
      staffList({ page: 1, limit: 1000000 }).then(res => {
        this.options.nurse_id = res.list;
      });
    },
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      policyList({ ...this.search, ...this.page }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
      });
    },
    // 点击详情
    tapBtn(type, row) {
      switch (type) {
        case "编辑":
          this.visitClient = true;
          this.updateId = row.id;
          this.titText = "更新";
          this.detail = row;
          break;
        case "添加":
          this.visitClient = true;
          this.titText = "添加";
          this.detail = new Detail();
          break;
      }
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.titText == "更新") {
            this.hint("更新此销售政策", "编辑");
          } else {
            this.hint("添加此销售政策", "添加");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提示
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确认提交
    confirm() {
      if (this.updateId) {
        updatePolicy({ ...this.detail, id: this.updateId }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.visitClient = false;
          this.getList();
        });
      } else {
        createPolicy({ ...this.detail }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.visitClient = false;
          this.getList();
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
.visit {
  .el-form-item {
    margin: 0px 20px 30px 0;
  }
}
.center {
  width: 100% !important;
}
.footerBtn {
  width: 100%;
  text-align: right;
}
</style>
